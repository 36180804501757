








import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component({
  components: {
  },
})
export default class InfoBlock extends Vue {
  @Prop(String)
  align: string

  @Prop(String)
  color: string

  @Prop({ type: Boolean, default: false })
  offset: boolean

  get classes() {
    return [
      'info-block',
      {
        [`info-block-${this.align}`]: this.align,
        'info-block-offset': this.offset !== false
      }
    ]
  }

  get isH5() {
    return AppModule.isH5
  }
}
