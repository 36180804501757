



























































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import CommentBlock from './components/CommentBlock.vue'

@Component({
  components: {
    InfoBlock,
    CommentBlock
  },
})
export default class ProgramFoodcourt extends Vue {
  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      'https://spics.wantu.cn/9c4af56f14d1a9a5a77d041fb9a36fab.png',
      'https://spics.wantu.cn/2549c9ecc4e1eb38bc0e7cdbcb3006e0.png',
      'https://spics.wantu.cn/ec2e52608b6c702483dcf1d381997c62.png',
      'https://spics.wantu.cn/3f50f97d160d9959fad1eefc1ed118e8.png',
      'https://spics.wantu.cn/1711885c59afff3a86b7efa6ea891685.png',
    ]
  }
}
